
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import PrintPage from './print.vue';
import EditForm from './form.vue';
import lotApply from './components/lotApply.vue';
import applyTable from './components/applyTable.vue';
import OfflinePayment from './offlinePayment.vue';
import { erpConfirm, errorMessage, exportFile, loadPage } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import router from '@/router';
import LotPayment from './components/lotPayment.vue';
export default defineComponent({
  name: 'payment',
  components: { ErpList, PrintPage, OfflinePayment, EditForm, lotApply, applyTable, LotPayment },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const formType = ref(1);
    const data = reactive({
      rowData: null,
    });
    let formData = ref({
      // 绑定表单数据
      payeeType: null, // 收款方类型
      operateType: null, // 操作类型：1.下一步 2.申请付款 3.编辑 4.取消 5.审核
      paymentOrderDtoList: [
        {
          orderId: null, // 订单号
          businessContractNo: null, // 销售合同号
          ContractNoOption: null, // 销售合同号Option
          ContractNoOptionObj: {}, // 该选项下值
          paymentType: null, // 付款类型
          paymentTypeDesc: null, // 付款类型描述
          isNeedJr: false, // 是否使用金融服务
          buyStmtNo: null, // 结算单号
          buyStmtNoData: {}, // 结算单号对象
          sundryFeeType: null, // 杂费类型
          sundryFeeTypeDesc: null, // 杂费类型展示
          sundryFeeTypeData: {}, // 杂费类型返回对象
          expectAmount: null, // 结算金额
          expectAmountDesc: null, // 结算金额
          applyPayAmount: null, // 申请付款金额
          remark: null, // 备注
          fundingId: null,
          customerId: null,
          attachmentDtoList: [], //文件凭证
          attachmentDtos: [], // 采购合同文件
        },
      ],
      accountName: null, // 收款方名称
      accountNo: null, // 收款方账号
      bankName: null, // 开户银行名称
      branchName: null, // 开户行支行名称
      addressCity: null, // 开户行支行名称
    });
    let paymentOrderDtoLists = ref([]);
    const dialog = reactive({
      visible: false,
    });
    const applyPay = () => {
      formType.value = 1;
      dialog.visible = true;
    };
    // 点击批量付款
    const lotApplyPay = () => {
      formType.value = 6;
      dialog.visible = true;
    };
    // 打印
    const toPrint = (row) => {
      formType.value = 5;
      data.rowData = row;
      showVisible();
    };
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const getSecondData = () => {
      formData.value = Object.assign({}, formData.value, data);
    };
    const cancelFirst = (status = false) => {
      dialog.visible = false; //提交审核最后一步
      if (status) {
        refreshTable(status);
      }
    };
    // 返回
    const cancel = (status = false, value?: number) => {
      if (value === 1) {
        closeVisible();
        dialog.visible = false;
      } else if (value === 2) {
        formType.value = 6;
      } else {
        formType.value = 61;
      }
      if (status) {
        refreshTable(status);
      }
    };
    // 线下支付的保存返回
    const payCancel = (status = false) => {
      dialog.visible = false;
      if (status) {
        refreshTable(status);
      }
    };
    const finish = (status = false) => {
      dialog.visible = false;
      if (status) {
        refreshTable(status);
      }
    };
    // 审核
    const getAuditDetails = (row) => {
      loadPage('paymentAudit', {
        id: row.id,
      });
    };

    // 收款账号
    const auditList = () => {
      router.push('/erp/financial/paymentAccount');
    };
    // 导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/payment/exportFmPaymentList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    const offlinePayment = (row) => {
      // 线下支付
      data.rowData = row;
      dialog.visible = true;
      if (row.yqzlPayStatus === 2 && row.payChannel === 1) {
        formType.value = 4;
      } else {
        formType.value = 3;
      }
    };
    const next = ({ value, list }) => {
      formType.value = value;
      formData.value.paymentOrderDtoList = list;
    };
    //批量申请第二步的下一步
    const next2 = (data) => {
      let { value, list, payeeType } = data;
      formType.value = value;
      paymentOrderDtoLists.value = list;
      formData.value.payeeType = payeeType;
    };
    // 查看详情
    const rowClick = (row, column) => {
      const { label } = column;
      if (label !== '操作' && label !== '支付凭证') {
        loadPage('paymentDetail', {
          id: row.id,
          version: row.version,
        });
      }
    };

    return {
      offlinePayment,
      dialog,
      applyPay,
      lotApplyPay,
      formType,
      toPrint,
      auditList,
      ...toRefs(data),
      getAuditDetails,
      exportFileFn,
      cancel,
      cancelFirst,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      paymentOrderDtoLists,
      rowClick,
      next,
      next2,
      formData,
      getSecondData,
      finish,
      payCancel,
    };
  },
});
