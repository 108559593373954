<template>
  <div v-loading="loading" element-loading-text="正在导入，请勿进行其他操作">
    <div>
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">导入申请付款表</div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="nextStep">下一步</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <!-- 步骤一 -->
      <div>
        <div style="padding: 0 16px">
          <div class="erp-form__title f16 import-top req no-leftborder" style="">选择导入文件</div>
          <div class="upload-box">
            <SingleFileContent
              :uploadUrl="customUrl"
              :isImport="true"
              :titleImg="''"
              :fromType="1"
              :fileType="fileType"
              :queryNum="0"
              :max="1"
              :typeImg="1"
              tips=" "
              :fileList="fileUrlsArray"
              @getFiles="getFiles"
              ref="importUpload"
              :autoUpload="false"
            >
            </SingleFileContent>
            <div class="upload-tips">
              允许类型：xls、xlsx&nbsp;&nbsp;&nbsp;文件大小上限：30M&nbsp;&nbsp;&nbsp;只支持上传1个文件&nbsp;&nbsp;&nbsp;<span
                class="record__link"
                @click="downLoadTmp"
                >《申请付款表》<span class="under">模板下载</span>
              </span>
            </div>
          </div>
        </div>
        <!-- 应收项目 -->
        <div v-if="errorShow">
          <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
            <div class="erp-form__title f16 no-leftborder">错误日志</div>
          </div>
          <div>
            <el-table :data="errorData" border class="egrid erp-table__list erp-view__table">
              <el-table-column label="错误行数" align="center" show-overflow-tooltip width="190" class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.cellRowDesc) }}
                </template>
              </el-table-column>
              <el-table-column label="错误原因" align="center" show-overflow-tooltip class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.description) }}
                </template>
              </el-table-column>
            </el-table>
            <div class="mali-erp-list-pagination">
              <pc-pagination
                ref="pcPagination"
                url="/malicrm/excel/queryExcelImportLineLogDetailList"
                :queryData="errorQueryForm"
                @change="queryCallBackFn"
              ></pc-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <tableFileContent
      class="margin-upload"
      width="970px"
      v-if="showUpLoadVisable"
      fileType=".pdf,.doc,.docx,.jpg,.png,.gif,.txt,.xls,.xlsx"
      :maxImg="10"
      tips="允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx   大小上限：30M   限制上传10个文件"
      :size="30"
      @getFileUrls="getFinalPayFile"
      ref="tableFileUpLoad"
      setKeyName="fmFileName"
      setKeyUrl="fmFileUrl"
      keyName="fmFileName"
      keyUrl="fmFileUrl"
      :fileUrlsArray="files"
    >
      <div></div>
    </tableFileContent> -->
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import PcPagination from '@/components/Pagination/Pagination.vue';
export default {
  components: {
    SingleFileContent,
    PcPagination,
  },
  props: {},
  data() {
    return {
      loading: false,
      errorShow: false,
      isAjaxLoading: false,
      errorData: [], // 错误信息列表
      errorQueryForm: {
        logId: null,
        pageNum: 1,
        pageSize: 10,
      },
      showUpLoadVisable: false, // 展示批量上传文件展示
      files: [],
      step: 1,
      visible: false,
      customUrl: process.env.VUE_APP_API_ROOT + '/malicrm/payment/importPayment',
      fileType: 'excel',
      fileUrlsArray: [],
      selectionList: [],
      formData: {
        dtoList: [],
      },
    };
  },

  methods: {
    showVisible() {
      this.visible = true;
    },
    closevisible() {
      this.visible = false;
    },
    queryCallBackFn(res) {
      this.errorData = res;
      // if (res.code === 200) {
      //   this.errorData = res;
      // }
    },
    selectionChange(row) {
      // 获取选中的行数据
      this.selectionList = row;
    },
    nextStep() {
      // 校验下一步,触发手动上传
      const len = this.$refs.importUpload.fileCount;

      if (len > 0) {
        this.$refs.importUpload.submitUpload();
        this.loading = true;
      } else {
        this.errorTip('请先上传文件');
      }
    },
    downLoadTmp() {
      // 下载模板地址
      this.$showFiles('申请付款表.xlsx', 'https://minio.malleegroup.com/template/申请付款表.xlsx', false);
    },
    getFiles(res) {
      // 获取导入数据，构建数据列
      this.loading = false;
      if (res.code === 200) {
        if (res.result.hasError === true) {
          this.errorTip('导入失败');
          this.errorQueryForm.logId = res.result.importLogId;
          this.errorShow = false;
          setTimeout(() => {
            this.errorShow = true;
          }, 50);
          this.step = 2;
        } else {
          this.successTip('导入成功');
          this.formData.dtoList = res.result.fmPaymentVoList;
          this.step = 2;
          this.$emit('next', { value: 61, list: this.formData.dtoList });
        }
      } else {
        this.errorTip(res.message);
      }
      setTimeout(() => {
        if (this.$refs.importUpload) {
          this.$refs.importUpload.fileCount = 0; // 必须置空，否则直接上传不会触发;
        }
        this.fileUrlsArray = [];
      }, 20);
      // this.$nextTick(() => {});
    },
    getLogData() {
      // 获取错误日志信息
      this.ajax({
        type: 'POST',
        url: '/malicrm/excel/queryExcelImportLineLogDetailList',
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.errorData = res.result.list;
          } else {
            this.errorTip(res.message);
          }
        },
        error: (res) => {
          //
        },
      });
    },
    getRowFileUrls({ data, index }) {
      // 单行文件编辑修改
      this.formData.dtoList[index].attachmentDtoList = data;
      // 行文件标记
    },
    getFinalPayFile({ data }) {
      // 获取上传的文件
      const indexs = this.getIndexs(this.formData.dtoList, this.selectionList, 'arapAuditSeq');
      indexs.forEach((index) => {
        this.formData.dtoList[index].attachmentDtoList = data;
      });
    },
    showUpLoad() {
      // 校验打开上传文件
      if (this.selectionList.length > 0) {
        this.showUpLoadVisable = false;
        setTimeout(() => {
          this.showUpLoadVisable = true;
        }, 10);
        setTimeout(() => {
          this.$refs.tableFileUpLoad.uploadVisable = true;
        }, 100);
      } else {
        this.errorTip('请选择关联数据');
      }
    },
    submit() {
      if (!this.isAjaxLoading) {
        this.isAjaxLoading = true;
        this.ajax({
          type: 'POST',
          url: '/malicrm/orderArap/saveApAuditFromExcel',
          data: {
            dtoList: this.formData.dtoList,
          },
          success: (res) => {
            this.isAjaxLoading = false;
            if (res.code === 200) {
              this.successTip('提交成功');
              this.$emit('cancel', true);
            } else {
              this.errorTip(res.message);
            }
          },
          error: (res) => {
            this.isAjaxLoading = false;
          },
        });
      }
    },
    back() {
      // 返回操作
      if (this.step === 2) {
        this.step = 1;
      } else {
        this.$emit('cancel', false, 1);
      }
    },
    getIndexs(targetArrays, currentArrays, name) {
      // 获取对应下标值
      const indexs = [];
      currentArrays.forEach((v) => {
        indexs.push(targetArrays.findIndex((item) => item[name] === v[name]));
      });
      return indexs;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-tips {
  display: flex;
  align-items: center;
  white-space: pre;
  font-size: 14px;
  color: #9a9a9a;
  position: absolute;
  top: 192px;
  .record__link {
    color: #2878ff;
  }
  .under {
    text-decoration: underline;
  }
}
.import-top {
  margin-bottom: 20px;
  margin-top: 20px;
}
.upload-box {
  position: relative;
}
.erp-form__title.req::after {
  content: '*';
  color: #f04838;
  margin-left: 4px;
}
.erp-form__title.no-leftborder {
  border-left: none;
  padding-left: 0;
}
</style>
