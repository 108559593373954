<template>
  <div class="payment-step3-box">
    <div class="erp-form__header header-bar__sticky op-16">
      <div class="erp-form__title">申请付款</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">提交审核</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="financial-form-title op-16">
      <div class="erp-form__title no-leftborder">付款订单</div>
    </div>
    <div class="claim_company m-20">
      <el-table
        :data="orderTableData"
        border
        class="egrid erp-table__list erp-view__table"
        :show-summary="orderTableData.length > 1"
        :summary-method="getSummaries"
      >
        <el-table-column align="center" label="销售合同号" show-overflow-tooltip class-name="no-pd table-visble required-icon">
          <template #default="scope">
            {{ filterName(scope.row.businessContractNo) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="签约主体" show-overflow-tooltip class-name="no-pd table-visble required-icon" width="120">
          <template #default="scope">
            {{ filterName(scope.row.principalName) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="付款类型"
          show-overflow-tooltip
          class-name="no-pd table-visble required-icon"
          width="100"
          prop="businessContractNo"
        >
          <template #default="scope">
            {{ filterName(scope.row.paymentTypeDesc) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算单号" show-overflow-tooltip class-name="no-pd table-visble required-icon" width="120">
          <template #default="scope">
            {{ filterName(scope.row.buyStmtNo) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="付款细目" show-overflow-tooltip class-name="no-pd table-visble required-icon">
          <template #default="scope">
            {{ filterName(scope.row.sundryFeeTypeName) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算金额" show-overflow-tooltip class-name="no-pd table-visble required-icon" width="120" prop="expectAmount">
          <template #default="scope">
            {{ filterName(scope.row.expectAmountDesc) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="申请付款金额"
          show-overflow-tooltip
          class-name="no-pd table-visble required-icon"
          width="120"
          prop="applyPayAmount"
        >
          <template #default="scope">
            {{ filterName(getThoundand(scope.row.applyPayAmount)) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" class-name="no-pd show-overflow-tooltip  table-visble required-icon" width="120">
          <template #default="scope">
            {{ filterName(scope.row.remark) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="凭据文件" class-name="no-pd table-visble required-icon" width="120">
          <template #default="scope">
            <tableFileContent :readOnly="true" :fileUrlsArray="scope.row.attachmentDtoList"></tableFileContent>
          </template>
        </el-table-column>
        <el-table-column align="center" label="采购合同" class-name="no-pd table-visble required-icon" width="120">
          <template #default="scope">
            <tableFileContent :readOnly="true" :fileUrlsArray="scope.row.attachmentDtos" key-name="name" key-url="url" key-id="id"></tableFileContent>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="financial-form-title op-16">
      <div class="erp-form__title">收款账号</div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item label="收款方名称" prop="accountName" v-if="payeeType === 6">
                <el-input :maxlength="40" v-model="formData.accountName" clearable placeholder="请输入收款方名称" class="mali-full__inputcom"></el-input>
              </el-form-item>
              <el-form-item label="收款方名称" prop="accountObj.accountName" v-else>
                <el-select
                  v-model="formData.accountObj"
                  class="mali-full__input"
                  clearable
                  filterable
                  remote
                  value-key="id"
                  @change="accountNameChange($event)"
                  :remote-method="getAccountNameList"
                  @blur="initAccountNameList"
                  placeholder="请选择收款方名称"
                >
                  <el-option v-for="item in accountNameList" :key="item.id" :label="item.accountName" :value="item">
                    <span v-if="item.accountName">{{ item.accountName + '(' + item.accountNo + ')' }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款方账号" :prop="payeeType === 6 ? 'accountNo' : ''">
                <el-input
                  :maxlength="25"
                  v-model="formData.accountNo"
                  clearable
                  :disabled="readOnly"
                  @blur="valInteger($event, 'accountNo')"
                  placeholder="请输入收款方账号"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户银行名称" :prop="payeeType === 6 ? 'bankName' : ''">
                <el-select
                  :disabled="readOnly"
                  v-model="formData.bankName"
                  class="mali-full__input"
                  clearable
                  filterable
                  remote
                  @change="getBankId"
                  :remote-method="getBankNameOption"
                  @blur="initBankNameOption"
                  placeholder="请输入开户行银行名称"
                >
                  <el-option v-for="item in bankNameOption" :key="item.id" :label="item.shortName" :value="item.shortName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户行支行名称" :prop="payeeType === 6 ? 'branchName' : ''">
                <el-input
                  :maxlength="40"
                  :disabled="readOnly"
                  v-model="formData.branchName"
                  clearable
                  placeholder="请输入开户行支行名称"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                v-if="payeeType === 6"
                label="开户行所在城市"
                :class="{
                  novalidate: !validateAddress,
                  municipality: isMunicipality,
                }"
                class="contact-address"
              >
                <cityArea @change="getBankAddress" :area-data="bankAddress" :level3="false" :size="'medium'"></cityArea>
                <span class="step2-city-tips" v-if="!validateAddress">请选择开户行所在城市</span>
              </el-form-item>
              <el-form-item v-else label="开户行所在城市" :prop="payeeType === 6 ? 'addressCity' : ''">
                <el-input
                  :maxlength="12"
                  :disabled="readOnly"
                  v-model="formData.addressCity"
                  placeholder="请输入开户行所在城市"
                  clearable
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import cityArea from '@/components/CitySelect/CitySelect.vue';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { _formatFloat, _thousandBitSeparator } from '@/utils/publicMethods';
export default {
  components: { cityArea, tableFileContent },
  props: ['orderTableData', 'payeeType', 'stepData', 'formType'],
  emits: ['cancel', 'formdata'],
  computed: {
    readOnly() {
      return this.payeeType == 6;
    },
  },
  data() {
    return {
      bankNameOption: [],
      bankAddress: {
        province: { name: '', id: '' },
        city: { name: '', id: '' },
        county: { name: '', id: '' },
      },
      validateAddress: true, // 校验
      isMunicipality: false, // 是否直辖市
      municipalityArr: ['北京市', '上海市', '天津市', '重庆市'],
      formData: {
        // 绑定表单数据
        accountName: null, // 收款方名称
        accountObj: {
          accountName: null,
          accountNo: null,
          id: null,
        }, // 收款方Obj
        accountNo: null, // 收款方账号
        bankName: null, // 开户银行名称
        branchName: null, // 开户行支行名称
        addressCity: null, // 开户行所在城市
        addressCityId: null, // 开户行地址-城市id
        addressProvince: null, // 开户行地址-省份
        addressProvinceId: null, // 开户行地址-省份id
        bankId: null, // 银行主键id(付款申请是需要通过id查询银行联行号)
      },
      rules: {
        'accountObj.accountName': [{ required: true, message: '请输入收款方名称', trigger: 'blur' }],
        accountName: [{ required: true, message: '请输入收款方名称', trigger: 'blur' }],
        accountNo: [{ required: true, message: '请输入收款方账号', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入开户银行名称', trigger: 'blur' }],
        branchName: [{ required: true, message: '请输入开户行支行名称', trigger: 'blur' }],
        addressCity: [
          {
            required: true,
            message: '请选择开户行所在城市',
            trigger: 'change',
          },
        ],
      },
      accountNameList: null,
    };
  },
  created() {
    this.getAccountNameList('');
    this.getBankNameOption('');
    const temp = this.stepData;
    const accountObj = {};
    accountObj.accountName = temp.accountName;
    accountObj.accountNo = temp.accountNo;
    accountObj.bankName = temp.bankName;
    accountObj.branchName = temp.branchName;
    accountObj.id = temp.id;
    this.accountNameList = [];
    this.accountNameList.push(accountObj);
    temp.accountObj = accountObj;
    this.formData = Object.assign({}, temp);
    const proName = this.formData.addressProvince;
    if (this.municipalityArr.includes(proName)) {
      this.isMunicipality = true;
    } else {
      this.isMunicipality = false;
    }
    this.bankAddress = {
      province: {
        name: this.formData.addressProvince,
        id: this.formData.addressProvinceId,
      },
      city: {
        name: this.formData.addressCity,
        id: this.formData.addressCityId,
      },
      county: {
        name: this.formData.addressDistrict,
        id: this.formData.areaId,
      },
    };
    if (this.formData.accountObj.id && !this.formData.accountObj.accountName) {
      const accountObj = {};
      this.formData.accountObj.id = null;
      this.accountNameList = [];
      this.accountNameList.push(accountObj);
      this.getAccountNameList('');
    }
  },
  methods: {
    getThoundand(val) {
      if (!isNaN(val)) {
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;
        const qStr = String(val).replace(reg, '$1,');
        return qStr;
      } else {
        return '';
      }
    },
    initBankNameOption() {
      // 失去焦点时重置筛选条件
      if (this.bankNameOption && this.bankNameOption.length <= 0) {
        this.getBankNameOption('');
      }
    },
    getBankNameOption(name) {
      // 获取开户行银行名称
      this.ajax({
        type: 'POST',
        url: '/malicrm/common/queryPayeeAccount',
        data: { shortName: name },
        success: (res) => {
          if (res.code === 200) {
            this.bankNameOption = res.result;
          }
        },
      });
    },
    // 获取银行id
    getBankId(val) {
      if (val) {
        const oindex = this.bankNameOption.findIndex((item) => {
          return item.shortName === val;
        });
        this.formData.bankId = this.bankNameOption[oindex].id;
        this.$refs.form.validateField(['bankName']);
      } else {
        this.formData.bankId = null;
        this.formData.bankName = null;
        this.$refs.form.validateField(['bankName']);
      }
    },
    // 校验只能输入整数
    valInteger(e, prop) {
      let value = e.target.value;
      value = value.replace(/[^\d]+/g, '');
      if (value.length > 0) {
        this.formData[prop] = String(value);
      } else {
        this.formData[prop] = null;
      }
    },
    // 收款方名称选择事件
    accountNameChange(dataObj) {
      if (dataObj && dataObj.accountName) {
        this.formData.accountName = dataObj.accountName; // 收款方名称
        this.formData.accountNo = dataObj.accountNo; // 收款方账号
        this.formData.bankName = dataObj.bankName; // 开户银行名称
        this.formData.branchName = dataObj.branchName; // 开户行支行名称
        this.formData.addressCity = dataObj.addressCity; // 开户行所在城市
        this.formData.addressCityId = dataObj.addressCityId; // 开户行地址-城市id
        this.formData.addressProvince = dataObj.addressProvince; // 开户行地址-省
        this.formData.addressProvinceId = dataObj.addressProvinceId; // 开户行地址-省
        this.formData.bankId = dataObj.bankId; // 银行主键id
        // 选择城市 填充
        const proName = dataObj.addressProvince;
        if (this.municipalityArr.includes(proName)) {
          this.isMunicipality = true;
          this.formData.addressCity = dataObj.addressProvince;
        } else {
          this.isMunicipality = false;
        }
      } else {
        this.formData.accountName = null; // 收款方名称
        this.formData.accountNo = null; // 收款方账号
        this.formData.bankName = null; // 开户银行名称
        this.formData.branchName = null; // 开户行支行名称
        this.formData.addressCity = null; // 开户行所在城市
        this.formData.addressCityId = null; // 开户行地址-城市id
        this.formData.addressProvince = null; // 开户行地址-省
        this.formData.addressProvinceId = null; // 开户行地址-省
        this.formData.bankId = null; // 银行主键id
        this.getAccountNameList('');
      }
      this.$refs.form.validate();
    },
    initAccountNameList() {
      // 失去焦点时重置筛选条件
      if (this.accountNameList && this.accountNameList.length <= 0) {
        this.getAccountNameList('');
      }
    },
    getAccountNameList(name) {
      const param = { refId: null, payeeType: null, accountName: name };
      param.payeeType = this.payeeType;
      if (param.payeeType === 1) {
        // 资金方
        param.refId = this.stepData.paymentOrderDtoList[0].fundingId;
      }
      if (param.payeeType === 4) {
        // 客户
        param.refId = this.stepData.paymentOrderDtoList[0].customerId;
      }
      this.ajax({
        type: 'POST',
        url: '/malicrm/fmPayeeAccount/queryBaseBank',
        data: param,
        success: (res) => {
          if (res.code === 200) {
            this.accountNameList = res.result;
          }
        },
      });
    },
    getBankAddress(data) {
      const proName = data.province.name;
      if (this.municipalityArr.includes(proName)) {
        this.isMunicipality = true;
      } else {
        this.isMunicipality = false;
      }
      this.validateAddress = true;
      this.formData.addressProvinceId = data.province.id;
      this.formData.addressProvince = data.province.name;
      this.formData.addressCityId = data.city.id;
      this.formData.addressCity = data.city.name;
    },
    showOnline(name, url) {
      this.$showFiles(name, url);
    },
    back() {
      this.$emit('cancel', false, 3);
      this.$emit('formdata', this.formData);
    },
    submit() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const proName = that.formData.addressProvince;
      if ((that.formData.addressProvinceId && that.formData.addressCityId) || that.municipalityArr.includes(proName)) {
        that.validateAddress = true;
      } else {
        that.validateAddress = false;
      }
      that.$refs.form.validate((valid) => {
        if (valid && that.validateAddress) {
          that.submitAudit();
        } else {
          this.errorTip('请填写所有带星号的必填项');
          return false;
        }
      });
    },
    submitAudit() {
      const data = Object.assign({}, this.stepData, this.formData);
      data.operateType = 2;
      if (this.formType === 2) {
        data.operateType = 3;
      }
      this.ajax({
        type: 'POST',
        url: '/malicrm/payment/savePayment',
        data,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功', 3000);
            this.$emit('finish', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = _thousandBitSeparator(_formatFloat(sums[index], 2));
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.name-tips {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.op-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.el-icon-circle-plus {
  cursor: pointer;
}
.m-20 {
  margin: 20px 0;
}
.financical-form {
  .financial-form-title {
    margin-top: 34px;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    .erp-form__title {
      font-size: 16px;
    }
  }
}
.color-link {
  cursor: pointer;
  color: #2878ff;
}
.step2-city-tips {
  color: #f56c6c;
  font-size: 12px;
  position: relative;
  top: -5px;
  display: inline-block;
}
.erp-form__title.no-leftborder {
  border-left: none;
  padding-left: 0;
}
</style>

