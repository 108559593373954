
import { defineComponent, reactive, ref } from 'vue';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { errorTip } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';
import XEUtils from 'xe-utils';
export default defineComponent({
  components: {
    tableFileContent,
  },
  props: ['list'],
  setup(props, ctx) {
    const tableFileUpLoad = ref('');
    const formData = reactive({
      dtoList: [],
    });
    const files = ref([]);
    formData.dtoList = Object.assign([], props.list);
    // 上传文件凭证组件的显示与隐藏
    let { visible, showVisible, closeVisible } = useVisible();
    // 选中的列表
    const selectionList = ref([]);
    //付款类型
    const payeeType = ref('');
    // 下一步
    const nextStep = () => {
      let totalMoney = 0;
      totalMoney = XEUtils.sum(selectionList.value, 'applyPayAmount');
      if (selectionList.value.length > 0) {
        if (totalMoney >= 0) {
          ctx.emit('next', { value: 62, list: selectionList.value, payeeType: payeeType.value });
        } else {
          errorTip('合计申请付款金额必须大于等于0');
        }
      } else {
        errorTip('请至少勾选一条数据');
      }
    };
    // 上传文件凭证
    const showUpLoad = () => {
      if (selectionList.value.length > 0) {
        visible.value = false;
        setTimeout(() => {
          visible.value = true;
        }, 10);
        setTimeout(() => {
          (tableFileUpLoad.value as any).uploadVisable = true;
        }, 100);
      } else {
        errorTip('请选择关联数据');
      }
    };
    // 获取对应下标值
    const getIndexs = (targetArrays, currentArrays, name) => {
      const indexs = [];
      currentArrays.forEach((v) => {
        (indexs as any).push(
          targetArrays.findIndex((item) => {
            return item[name] === v[name];
          })
        );
      });
      return indexs;
    };
    // 上传文件凭证，获取返回数据
    const getFinalPayFile = ({ data }) => {
      const indexs = getIndexs(formData.dtoList, selectionList.value, 'arapAuditSeq');
      indexs.forEach((v) => {
        (formData.dtoList[v] as any).attachmentDtoList = data;
      });
    };
    // 返回
    const back = () => {
      ctx.emit('cancel', false, 2);
    };
    // 勾选表格
    const selectionChange = (row) => {
      payeeType.value = row[0].payeeType;
      selectionList.value = row;
    };
    // 获取当前行操作返回的文件数据
    const getRowFileUrls = ({ data, index }) => {
      (formData.dtoList[index] as any)['attachmentDtoList'] = data;
    };
    return {
      files,
      formData,
      nextStep,
      back,
      selectionList,
      selectionChange,
      getRowFileUrls,
      payeeType,
      showUpLoad,
      visible,
      showVisible,
      closeVisible,
      getIndexs,
      getFinalPayFile,
      tableFileUpLoad,
    };
  },
});
