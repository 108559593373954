/* eslint-disable @typescript-eslint/no-this-alias */
<template>
  <div class="print-center-dialog">
    <div class="print-wrap">
      <div ref="print">
        <table class="print-table table1">
          <tr>
            <td width="140">审批编号</td>
            <td>{{ orderData.paymentNo }}</td>
          </tr>
          <tr>
            <td width="140">申请人</td>
            <td>{{ orderData.creator }}</td>
          </tr>
          <tr>
            <td width="140">申请时间</td>
            <td>{{ orderData.createdTime }}</td>
          </tr>
          <tr>
            <td width="140">审批人</td>
            <td>{{ orderData.auditOperator }}</td>
          </tr>
          <tr>
            <td>审批通过时间</td>
            <td>{{ orderData.auditTime }}</td>
          </tr>
          <tr>
            <td>付款事由</td>
            <td>
              <p
                v-for="(item, index) in orderData.orderPrintVos"
                class="pay-reason"
                :key="index"
              >
                <span>{{ item.businessContractNo }}</span>
                <span v-if="item.paymentTypeDesc">
                  / {{ item.paymentTypeDesc }}</span
                >
                <span v-if="item.applyPayAmountDesc">
                  / 实付： {{ item.applyPayAmountDesc }}</span
                >
                <span v-if="item.sundryFeeTypeDesc">
                  / {{ item.sundryFeeTypeDesc }}</span
                >
                <span v-if="item.expectAmountDesc"
                  >/ 应结：{{ item.expectAmountDesc }}</span
                >
                <span v-if="item.remark"> / 备注：{{ item.remark }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>付款总额</td>
            <td>{{ orderData.totalAmountDesc }}</td>
          </tr>
          <tr>
            <td>实际付款时间</td>
            <td>{{ orderData.yqzlTransactionTime }}</td>
          </tr>
          <tr>
            <td>银行流水号</td>
            <td>{{ orderData.flowNo }}</td>
          </tr>
          <tr>
            <td>支付对象</td>
            <td>{{ orderData.accountName }}</td>
          </tr>
          <tr>
            <td>开户银行名称</td>
            <td>{{ orderData.bankName }}</td>
          </tr>
          <tr>
            <td>开户行支行名称</td>
            <td>{{ orderData.branchName }}</td>
          </tr>
          <tr>
            <td>银行账号</td>
            <td>{{ orderData.accountNo }}</td>
          </tr>
        </table>
        <div class="print-time">
          打印时间：<b>{{ printTime }}</b>
          <span
            >打印人：<b>{{ orderData.printUser }}</b></span
          >
        </div>
      </div>
    </div>
    <div class="print-bottom">
      <span class="erp-default__btn" @click="back">关闭</span>
      <span class="erp-primary__btn" @click="doPrint">打印</span>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import Print from '@/utils/print/index';
export default {
  props: ['id'],
  data() {
    return {
      orderData: {
        paymentNo: null,
        creator: null,
        createdTime: null,
        auditOperator: null,
        auditTime: null,
        orderPrintVos: [],
        totalAmountDesc: null,
        yqzlTransactionTime: null,
        flowNo: null,
        accountName: null,
        bankName: null,
        branchName: null,
        accountNo: null,
        printTime: null,
      },
      printTime: null,
    };
  },
  created() {
    this.updatePrintTime();
    this.initData(this.id);
  },
  methods: {
    doPrint() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.updatePrintTime();
      setTimeout(function () {
        Print(that.$refs.print);
      }, 100);
    },
    initData(id) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.ajax({
        type: 'GET',
        url: `/malicrm/payment/printPayment/${id}`,
        loading: true,
        success: (res) => {
          setTimeout(() => {
            that.$pcNProgress.done();
          }, 300);
          if (res.code === 200) {
            that.orderData = Object.assign({}, that.orderData, res.result);
            this.updatePrintTime();
          } else {
            that.errorTip(res.message);
          }
        },
      });
    },
    back() {
      this.$emit('cancel', false);
    },
    updatePrintTime() {
      this.printTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>
<style lang="scss">
.print-center-dialog {
  .print-bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 7px;
    line-height: 30px;
    border-top: 1px solid rgba(220, 223, 230, 1);
    padding-bottom: 8px;
    span {
      padding: 0 25px;
      border: 1px solid rgba(220, 223, 230, 1);
      background-color: #fff;
      vertical-align: middle;
      font-size: 14px;
      color: #303133;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      border-radius: 2px;
    }
    .erp-default__btn {
      background: #fff;
      cursor: pointer;
      border: 1px solid rgba(220, 223, 230, 1);
      color: #303133;
    }
    .erp-primary__btn {
      background: #2878ff;
      color: #fff;
      border-color: #2878ff;
      margin-left: 8px;
      margin-right: 5px;
    }
  }
}
.print-wrap {
  width: 844px;
  height: 100%;
  margin: 30px auto;
  margin-top: 18px;
  border: 1px dashed #e5e9f2;
  padding: 40px;
  min-height: 595px;
}
.print-table {
  border: 1px solid rgba(137, 137, 137, 1);
  width: 100%;
  border-collapse: collapse;
  line-height: 30px;
  font-size: 14px;
}
.print-table tr td,
.print-table tr th {
  border: 1px solid rgba(137, 137, 137, 1);
  color: #303133;
}
.print-time {
  text-align: right;
  margin-top: 9px;
  color: #606266;
}
.print-time > span {
  margin-left: 20px;
  display: inline-block;
}
.print-time b {
  color: #303133;
  font-weight: 400;
}
.print-table.order {
  margin-top: 10px;
}
.print-table.table1 {
  td {
    vertical-align: middle;
    text-indent: 15px;
    .pay-reason {
      border-bottom: 1px solid rgba(137, 137, 137, 1);
    }
  }
}
.print-table.order {
  td {
    text-align: center;
    line-height: 1.2;
    padding: 0 15px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 8px;
  }
}
.print-table.table1 td .pay-reason:last-child {
  border: none;
}
</style>
