import { } from '@/api';
import { ErpTableOptions } from '@/types/type';
import { showFiles } from '@/utils/publicMethods';
import { defineComponent, ref, toRaw } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '付款申请号',
      prop: 'paymentNo',
      value: '',
      minWidth: 144,
      type: 'input',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      value: '',
      minWidth: 156,
      type: 'input',
    },
    {
      label: '申请付款金额',
      prop: 'applyPayAmount',
      propDesc: 'applyPayAmountDesc',
      value: [],
      type: 'number',
      minWidth: 157,
      filterProp: ['applyPayAmountMinVal', 'applyPayAmountMaxVal'],
    },
    {
      label: '审核通过金额',
      prop: 'agreePayAmount',
      propDesc: 'agreePayAmountDesc',
      minWidth: 157,
      value: [],
      type: 'number',
      filterProp: ['agreePayAmountMinVal', 'agreePayAmountMaxVal'],
    },
    {
      label: '付款账号',
      prop: 'principalFullName',
      value: '',
      minWidth: 184,
      type: 'input',
    },
    {
      label: '实际付款金额',
      prop: 'actualPayAmount',
      propDesc: 'actualPayAmountDesc',
      value: [],
      type: 'number',
      minWidth: 157,
      filterProp: ['actualPayAmountMinVal', 'actualPayAmountMaxVal'],
    },
    {
      label: '流水号',
      prop: 'flowNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '交易时间',
      prop: 'yqzlTransactionTime',
      value: [],
      type: 'time',
      minWidth: 156,
      filterProp: ['yqzlTransactionTimeStartDate', 'yqzlTransactionTimeEndDate'],
    },
    {
      label: '收款方名称',
      prop: 'accountName',
      value: '',
      minWidth: 184,
      type: 'input',
    },
    {
      label: '收款方账号',
      prop: 'accountNo',
      value: '',
      minWidth: 149,
      type: 'input',
    },
    {
      label: '开户行银行名称',
      prop: 'bankName',
      value: '',
      minWidth: 169,
      type: 'input',
    },
    {
      label: '开户行支行名称',
      prop: 'branchName',
      value: '',
      minWidth: 210,
      type: 'input',
    },
    {
      label: '支付渠道',
      prop: 'payChannel',
      propDesc: 'payChannelDesc',
      value: [],
      type: 'multiSelect',
      minWidth: 125,
      options: 'pay_channel',
    },
    {
      label: '支付状态',
      prop: 'yqzlPayStatus',
      propDesc: 'yqzlPayStatusDesc',
      value: [],
      minWidth: 125,
      type: 'multiSelect',
      options: 'yqzl_pay_status',
    },
    {
      label: '支付凭证',
      prop: 'payTicket',
      propDesc: 'payTicket',
      value: '',
      type: 'input',
      className: '',
      isFilter: false,
      isSort: false,
      minWidth: 100,
      component: defineComponent({
        template: `
        <div class="record__link" @click="view(row)" v-if="row.payUrl">查看</div>
          <div v-else>-</div>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const view = (row) => {
            showFiles(row.payTicket, row.payUrl);
          };
          return { view };
        },
      }),
    },
    {
      label: '支付失败原因',
      prop: 'yqzlFailMsg',
      value: '',
      minWidth: 210,
      type: 'input',
    },
    {
      label: '线下支付备注',
      prop: 'payRemark',
      value: '',
      minWidth: 210,
      type: 'input',
    },
    {
      label: '创建人',
      prop: 'creator',

      value: '',
      minWidth: 140,
      type: 'input',
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      value: [],
      type: 'time',
      minWidth: 156,
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      value: '',
      type: 'input',
      minWidth: 140,
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      value: [],
      type: 'time',
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
      minWidth: 160,
    },

    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 290,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
    
        <el-button v-if="row.needAudit && hasPermission('erp:payment:auditFmPayment')" plain type="primary" @click='getAuditDetails(row)'>审核</el-button>
        <el-button
          v-if="(row.yqzlPayStatus==6||row.yqzlPayStatus==3||row.yqzlPayStatus==5 || row.yqzlPayStatus==7 ||(row.yqzlPayStatus==2&&row.payChannel==1))&&hasPermission('erp:payment:offLinePay')"
          plain type="primary" @click='offlinePayment(row)'>线下支付
        </el-button>
        <el-button v-if="row.needPrint && hasPermission('erp:payment:exportFmPaymentList')" plain type="primary" @click='toPrint(row)'>打印</el-button>
        <el-button type="primary" v-if="row.flowNo && row.memoUrl && row.yqzlPayStatus == 2 && hasPermission('erp:fm:payment:downloadWatermark')" plain @click='downLoadWaterBill(row)'>下载水单</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const offlinePayment = (row) => {
            Instance.setupState.offlinePayment(toRaw(row));
          };
          const getAuditDetails = (row) => {
            Instance.setupState.getAuditDetails(toRaw(row));
          };
          const toPrint = (row) => {
            Instance.setupState.toPrint(toRaw(row));
          };
          const downLoadWaterBill = (row) => {
            showFiles(row.memoName, row.memoUrl, false);
          };
          return { getAuditDetails, toPrint, downLoadWaterBill, offlinePayment };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
