<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">线下支付</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="支付渠道" prop="payChannel">
                <el-input :disabled="true" v-model="formData.payChannelDesc" placeholder="请选择支付渠道" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实际付款金额" prop="actualPayAmountDesc">
                <el-input v-model="formData.actualPayAmountDesc" :disabled="true" placeholder="请输入实际付款金额" class="mali-full__inputcom"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付状态" prop="yqzlPayStatus">
                <el-select v-model="formData.yqzlPayStatus" placeholder="请选择支付状态" style="width: 100%">
                  <el-option label="已支付" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="交易时间" prop="yqzlTransactionTime">
                <el-date-picker
                  v-model="formData.yqzlTransactionTime"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm"
                  placeholder="请选择交易时间"
                  prefix-icon=" "
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="流水号" prop="flowNo">
                <el-input
                  :maxlength="15"
                  v-model="formData.flowNo"
                  clearable
                  @blur="changeContractNo($event)"
                  placeholder="请输入流水号"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="线下支付备注" prop="payRemark">
                <el-input
                  :maxlength="100"
                  v-model="formData.payRemark"
                  clearable
                  placeholder="请输入线下支付备注"
                  type="textarea"
                  resize="none"
                  :rows="5"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="付款凭据" prop="attachmentDtoUrl">
                <div class="upload-box">
                  <SingleFileContent :fromType="1" fileType="noZip" :max="1" :fileList:="fileUrlsArray" @success="getFileUrls"> </SingleFileContent>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import { formatTime } from '@/utils/publicMethods';
export default {
  components: {
    SingleFileContent,
  },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
    showData: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fileUrlsArray: [],
      formData:
        // 绑定表单数据
        {
          attachmentDtoName: null,
          attachmentDtoUrl: null,
          attachmentDto: null,
          flowNo: null,
          id: null,
          payChannel: 1,
          payChannelDesc: '线下支付',
          version: null,
          yqzlPayStatus: null,
          yqzlTransactionTime: null,
        },
      disabled: false,
      rules: {
        // 检验规则
        payChannel: [
          {
            required: true,
            message: '请选择支付渠道',
            trigger: 'change',
          },
        ],
        actualPayAmountDesc: [
          {
            required: true,
            message: '请输入实际付款金额',
            trigger: 'blur',
          },
        ],
        yqzlPayStatus: [
          {
            required: true,
            message: '请选择支付状态',
            trigger: 'change',
          },
        ],
        yqzlTransactionTime: [
          {
            required: true,
            message: '请选择交易时间',
            trigger: 'change',
          },
        ],
        flowNo: [
          {
            required: true,
            message: '请输入流水号',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    if (this.formType === 3) {
      this.formData.actualPayAmountDesc = this.editData.agreePayAmountDesc;
      this.formData.id = this.editData.id;
      this.formData.version = this.editData.version;
    } else {
      this.initData(this.editData.id);
    }
  },
  methods: {
    initData(id) {
      this.ajax({
        type: 'GET',
        url: `/malicrm/payment/offLinePayDetail/${id}`,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            const file = JSON.parse(JSON.stringify(res.result.attachmentVo));
            this.formData.attachmentDto = file;
            this.formData.attachmentDtoUrl = file.url;
            this.formData.attachmentDtoName = file.name;
            this.fileUrlsArray = [file];
          }
        },
      });
    },
    getFileUrls(data) {
      if (data.length > 0) {
        this.formData.attachmentDtoUrl = data[0].url;
        this.formData.attachmentDtoName = data[0].name;
      } else {
        this.formData.attachmentDtoUrl = null;
        this.formData.attachmentDtoName = null;
        this.formData.attachmentDto = null;
      }
    },
    changeContractNo(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符
      this.formData.flowNo = value;
    },

    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.attachmentDto = {
        name: params.attachmentDtoName,
        url: params.attachmentDtoUrl,
      };
      if (!params.attachmentDtoName) {
        params.attachmentDto = null;
      }
      params.yqzlTransactionTime = formatTime(params.yqzlTransactionTime, 'YYYY-MM-DD HH:mm');
      delete params.attachmentVo;
      this.ajax({
        type: 'POST',
        url: '/malicrm/payment/offLinePay',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('cancel', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
