import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_EditForm = _resolveComponent("EditForm")!
  const _component_OfflinePayment = _resolveComponent("OfflinePayment")!
  const _component_lot_apply = _resolveComponent("lot-apply")!
  const _component_apply_table = _resolveComponent("apply-table")!
  const _component_lot_payment = _resolveComponent("lot-payment")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!
  const _component_PrintPage = _resolveComponent("PrintPage")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/payment/queryFmPaymentListWithPage",
      rowId: "id",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "payment",
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('erp:payment:exportFmPaymentList'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "erp-default__btn ml-8",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportFileFn && _ctx.exportFileFn(...args)))
            }, "导出"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:fmPayeeAccount:queryPayeeAccountListWithPage'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "erp-primary__btn ml-8",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.auditList && _ctx.auditList(...args)))
            }, "收款账号"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:payment:savePayment'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "erp-primary__btn ml-8",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.applyPay && _ctx.applyPay(...args)))
            }, "申请付款"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:fm:importPayment'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: "erp-primary__btn ml-8",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.lotApplyPay && _ctx.lotApplyPay(...args)))
            }, "批量导入付款"))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columOptions", "onRowClick"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.dialog.visible,
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dialog.visible = false)),
      erp: "",
      class: "no-padding fix-top-header",
      width: 1100
    }, {
      default: _withCtx(() => [
        (_ctx.formType == 1 || _ctx.formType == 2)
          ? (_openBlock(), _createBlock(_component_EditForm, {
              key: 0,
              formType: _ctx.formType,
              onCancel: _ctx.cancelFirst
            }, null, 8, ["formType", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.formType == 3 || _ctx.formType == 4)
          ? (_openBlock(), _createBlock(_component_OfflinePayment, {
              key: 1,
              onCancel: _ctx.payCancel,
              editData: _ctx.rowData,
              formType: _ctx.formType
            }, null, 8, ["onCancel", "editData", "formType"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 6)
          ? (_openBlock(), _createBlock(_component_lot_apply, {
              key: 2,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              onNext: _ctx.next
            }, null, 8, ["formType", "onCancel", "onNext"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 61)
          ? (_openBlock(), _createBlock(_component_apply_table, {
              key: 3,
              formType: _ctx.formType,
              onCancel: _ctx.cancel,
              onNext: _ctx.next2,
              list: _ctx.formData.paymentOrderDtoList
            }, null, 8, ["formType", "onCancel", "onNext", "list"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 62)
          ? (_openBlock(), _createBlock(_component_lot_payment, {
              key: 4,
              formType: 1,
              onCancel: _ctx.cancel,
              onFinish: _ctx.finish,
              orderTableData: _ctx.paymentOrderDtoLists,
              stepData: _ctx.formData,
              payeeType: _ctx.formData.payeeType,
              onFormdata: _ctx.getSecondData
            }, null, 8, ["onCancel", "onFinish", "orderTableData", "stepData", "payeeType", "onFormdata"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"]),
    (_ctx.visible)
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          modelValue: _ctx.visible,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visible) = $event)),
          "custom-class": "print-dialog",
          title: "付款审批",
          width: "904px"
        }, {
          default: _withCtx(() => [
            (_ctx.formType == 5)
              ? (_openBlock(), _createBlock(_component_PrintPage, {
                  key: 0,
                  id: _ctx.rowData.id,
                  onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeVisible()))
                }, null, 8, ["id"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}